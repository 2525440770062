<template lang="html">
  <div class="content-box">
    <div class="title-area">
      <h2 class="title-b">설정 / 단말기 관리 / 단말기 등록</h2>
    </div>

    <div class="form-box mt-sm">
      <div class="form-item">
        <label class="form-title">단말기 명 <span class="requisite">*</span></label>
        <div class="form-element size-a">
          <input type="text" v-model="deviceName" placeholder="단말기 명" class="element-input size-lg">
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="deviceName==''">필수 입력 사항 입니다.</p>
        </div>
      </div>
      
      <div class="form-item">
        <label class="form-title">단말 ID (시리얼) <span class="requisite">*</span></label>
        <div class="form-element size-a">
          <input type="text" v-model="deviceID" placeholder="단말 ID" class="element-input size-lg" :readonly="deviceCheck" />
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="deviceID==''">필수 입력 사항 입니다.</p>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="deviceID!='' && deviceID.length != 7">단말 아이디 자리수는 7자리 까지 입니다.</p>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="deviceID.length == 7 && !deviceCheck">중복확인이 필요합니다.</p>
        </div>
        <button type="button" v-if="!deviceCheck" class="element-btn size-lg bg-blue-gray" @click="checkDeviceID()">확인</button>
        <button type="button" v-else class="element-btn size-lg bg-red" @click="cancelDeviceID()">취소</button>
      </div>

      <!-- 현재 상태를 등록할때 필요 합니까?
      <div class="form-item">
        <label class="form-title">현재상태</label>
        <div class="form-element size-a">
          <input type="text" placeholder="" class="element-input size-lg" value="미확인" readonly>
        </div>
      </div> 
      -->

      <!-- 단말기 연럭처를 등록할때 필요 합니까? information 시 전송 받습니다.
      <div class="form-item">
        <label class="form-title">단말기 연락처</label>
        <div class="form-element size-a">
          <input type="text" placeholder="" class="element-input size-lg" value="미확인" readonly>
        </div>
      </div>
      -->

      <div class="form-item">
        <label class="form-title">고객사/운용부서 <span class="requisite">*</span></label>
        <div class="form-element size-a">
          <t-rich-select
            :options="companyList"
            class="border border-solid rounded-md text-gray-700 border-gray-300 outline-none w-62"
            textAttribute="companyName"
            valueAttribute="companyGuid"
            :hideSearchBox="false"
            v-model="companyGuid"
            :fixedClasses="richSelectXL"
          >
          </t-rich-select>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="companyGuid==''">필수 입력 사항 입니다.</p>
        </div>
      </div>
      <div class="form-item row-type">
        <label class="form-title">관리자 메모</label>
        <div class="form-element size-b">
          <textarea name="" v-model="deviceDescription" class="element-textarea" placeholder="메모"></textarea>
        </div>
      </div>
    </div>

    <div class="page-btn-area">
      <button type="button" class="element-btn bg-red size-lg" @click="cancelRegist()">취소</button>
      <!-- <button type="button" class="element-btn size-lg bg-blue" @click="deviceRegist()" :disabled="checkRegistDisable()">저장</button> -->
      <button type="button" class="element-btn size-lg bg-blue" @click="deviceRegist()">저장</button>
    </div>
  </div>
</template>

<script>
import { requestApi } from "@/utils/api"
import { mapGetters, mapActions } from "vuex"
import { richSelectXL } from "@/references/fixedClass"

export default {
  created () {
    this.getCompanyListAll()
  },
  computed: {
    ...mapGetters(["getIsOverlay"]),
  },
  data () {
    return {
      richSelectXL,
      deviceID: '',
      deviceName: '',
      companyGuid: '',
      deviceDescription: '',
      companyList: [],
      deviceCheck: false,
    }
  },
  methods: {
    ...mapActions(["chgIsOverlay","chgIsAlert"]),
    async getCompanyListAll () {
      let reqObj = {}
      reqObj.target = "/company/listall";
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      this.companyList = []
      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.companyList = result.msg.retData
      }
    },
    async checkDeviceID () {
      if(this.deviceID === "") {
        this.chgIsAlert({status:true,string:'ID를 입력해주세요.'})
        return;
      }

      if(this.deviceID.length !== 7) {
        this.chgIsAlert({status:true,string:'단말 ID는 7 자리 입니다..'})
        return;
      }

      const idReg = /^[a-z0-9]{7}$/;
      if(!idReg.test(this.deviceID)) {
        this.chgIsAlert({status:true,string:'아이디는 영문자 또는 숫자 7자 입니다.(소문자)'})
        this.deviceID = '';
        return;
      }

      let reqObj = {}
      reqObj.target = `/device/checkID/${this.deviceID}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      this.deviceCheck = false
      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        if(result.msg.retData === "success") {
          this.chgIsAlert({status:true,string:'사용가능한 ID 입니다.'})
          
          this.deviceCheck = true
        }
        else {
          this.chgIsAlert({status:true,string:'사용중인 ID 입니다.'})
        }
      }
    },
    cancelDeviceID () {
      // this.deviceID = ""
      this.deviceCheck = false
    }, 
    checkRegistDisable () {
      let retVal = true;
      if(this.deviceID !== "" && this.deviceName !== "" && this.companyGuid !== "" && this.deviceCheck && this.deviceID.length === 7) retVal = false;
      return retVal;
    },
    deviceRegist () {
      if(this.deviceName === '') {
        this.chgIsAlert({status:true,string:'단말기 명을 입력해 주세요.'})
        return;
      }

      if(this.deviceID === '') {
        this.chgIsAlert({status:true,string:'단말기 ID를 입력해 주세요.'})
        return;
      }

      if(!this.deviceCheck) {
        this.chgIsAlert({status:true,string:'단말기 ID 중복 확인을 해주세요.'})
        return;
      }

      if(this.companyGuid === '') {
        this.chgIsAlert({status:true,string:'고객사/운용부서를 선택해 주세요.'})
        return;
      }

      this.deviceRegistAction()
    },
    async deviceRegistAction () {
      let reqObj = {}
      reqObj.target = `/device/regist`;
      reqObj.method = "post";
      reqObj.params = {};
      reqObj.data = {
        "companyGuid": this.companyGuid,
        "deviceDescription": this.deviceDescription,
        "deviceID": this.deviceID,
        "deviceLocation": "",
        "deviceName": this.deviceName
      };
      reqObj.headers = { "accept":"application/json" };

      this.deviceCheck = false
      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.chgIsAlert({status:true,string:'등록 했습니다.'})
        
        this.$router.push({name:'DeviceAdmin'})
      }
    },
    cancelRegist () {
      this.$router.push({name:'DeviceAdmin'})
    }
  }
  
}
</script>
<style lang="">
  
</style>